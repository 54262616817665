import React, { useEffect } from 'react';

import { useLingui } from '@lingui/react';
import { Controller, useFormContext } from 'react-hook-form';
import type { Country } from 'react-phone-number-input';
import { isSupportedCountry } from 'react-phone-number-input';
// @ts-expect-error No type declarations for this package
import ru from 'react-phone-number-input/locale/ru';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

import { Input } from '@tf/ui';

import 'react-phone-number-input/style.css';

import { useConfigContext, useDisabledAutocompleteAttributes } from '../../../hooks';
import { TooltipIcon } from '../TooltipIcon';
import type { DefaultInputProps } from '../types';

const inputLocales: Record<string, any> = { ru };

export function PhoneInput({ name, label, isReadOnly, isRequired, tooltip }: DefaultInputProps) {
	const { i18n } = useLingui();
	const form = useFormContext();
	const disabledAutocompleteAttributes = useDisabledAutocompleteAttributes();

	useEffect(() => {
		const value = form.getValues(name);
		form.setValue(name, value);
	}, []);

	const defs = useConfigContext((s) => s);
	const countries = defs.getEnumOptions('common__enum__country') || [];
	const europeCountryCodes = countries
		.filter((c) => c.group === 'Europe' && isSupportedCountry(c.value))
		.map((c) => c.value as Country);

	return (
		<Controller
			name={name}
			control={form.control}
			render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
				return (
					<Input.Wrapper label={label} labelProps={{ required: isRequired }}>
						<PhoneInputWithCountry
							name={name}
							labels={inputLocales[i18n.locale] ?? undefined}
							countryOptionsOrder={['🌐', ...europeCountryCodes, '|', '...']}
							error={Boolean(error)}
							readOnly={isReadOnly}
							onChange={(value: string | null) => {
								if (value === null) onChange('');
								else onChange(value);
							}}
							withCountryCallingCode
							international
							value={value}
							inputComponent={Input}
							ref={ref}
							numberInputProps={{
								rightSection: <TooltipIcon tooltip={tooltip} error={error} />,
							}}
							{...disabledAutocompleteAttributes}
						/>
					</Input.Wrapper>
				);
			}}
		/>
	);
}
